import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import logo from './logo.svg';
import './App.css';
import { Grid, GridItem, Badge, Box, Button, ChakraProvider, Heading, IconButton, Input, Menu, MenuButton, MenuItem, MenuList, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Stack, Table, TableCaption, TableContainer, Tbody, Td, Textarea, Tfoot, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react';
import { Text } from '@chakra-ui/react'
import { CSSProperties } from '@emotion/serialize';

function TitleCard(props: any) {
  return (
    <div className='TitleCard'><Text fontSize='5xl'>{props.title}</Text></div>
  );
}

function Card(props: any) {
  return (
    <div className='App-card' style={{ backgroundImage: 'url(' + props.url + ')' }}>
    </div>
  );
}

function Panel(props: any) {
  return (
    <div className='Panel'>
      {props.name === 'Infos' && (
        <iframe
          src="https://discord.com/widget?id=626167622400213058&theme=dark"
          width="350"
          height="500"
          frameBorder="0"
          sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
        />
      )}
      {props.name === 'Restos' && (
        <iframe style={{ borderRadius: '2%' }} src="https://www.google.com/maps/d/u/0/embed?mid=1uC4Gl_WamX9oSF-L2VWs7H--WwWXYPU&ehbc=2E312F" width="800" height="500"></iframe>
      )}
    </div>
  );
}

function CardBar(props: any) {
  return (
    <div style={{ zIndex: '2' }}>
      <Grid templateColumns='repeat(5, 1fr)' gap={16} style={{ display: 'inline-grid',  marginLeft: '42vw' }}>
        <GridItem onMouseEnter={() => props.changeTitle('Le serveur en direct')} onMouseLeave={() => props.changeTitle()} onClick={() => props.showPanel('Infos')}>
          <Card 
            url='https://d15f34w2p8l1cc.cloudfront.net/overwatch/5c18e39ce567ee8a84078f775b9f76a2ba891de601c059a3d2b46b61ae4afb42.png'
          />
        </GridItem>
        <GridItem onMouseEnter={() => props.changeTitle('Les bons restos')} onMouseLeave={() => props.changeTitle()} onClick={() => props.showPanel('Restos')}>
          <Card url='https://d15f34w2p8l1cc.cloudfront.net/overwatch/6cfb48b5597b657c2eafb1277dc5eef4a07eae90c265fcd37ed798189619f0a5.png'/>
        </GridItem>
        <GridItem><Card url='https://d15f34w2p8l1cc.cloudfront.net/overwatch/000beeb5606e01497897fa9210dd3b1e78e1159ebfd8afdc9e989047d7d3d08f.png'/></GridItem>
        <GridItem><Card url='https://d15f34w2p8l1cc.cloudfront.net/overwatch/8819ba85823136640d8eba2af6fd7b19d46b9ee8ab192a4e06f396d1e5231f7a.png'/></GridItem>
        <GridItem><Card url='https://d15f34w2p8l1cc.cloudfront.net/overwatch/bd9c8e634d89488459dfc1aeb21b602fa5c39aa05601a4167682f3a3fed4e0ee.png'/></GridItem>
      </Grid>
    </div>
  );
}

function Footer(props: any) {
  const [title, setTitle] = useState('');
  const [showTitle, setShowTitle] = useState(false);

  const changeTitle = (title: string) => {
    if (showTitle) {
      setShowTitle(false);
    } else {
      setShowTitle(true);
      setTitle(title);
    }
  };

  return (
    <>
      <div className='Footer'>
        <CardBar changeTitle={changeTitle} showPanel={props.showPanel} />
      </div>
      {showTitle && title != '' && <TitleCard title={title} />}
    </>
  );
}

function App() {
  const [showPanel, setShowPanel] = useState(false);
  const [panelName, setPanelName] = useState('');
  document.body.style.overflow = 'hidden';

  const setPanel = (panelname: string) => {
    if (showPanel) {
      if (panelname != panelName) {
        setPanelName(panelname);
      } else {
        setShowPanel(false);
      }
    } else {
      setPanelName(panelname);
      setShowPanel(true);
    }
  };

  return (
    <div>
      <Heading 
        style={{ textAlign: 'center', width: '100%', position: 'fixed', zIndex: '2', backdropFilter: 'blur(10px)', pointerEvents: 'none' }}
      >
        <Text fontSize='5xl' style={{ fontFamily: 'Overwatch', color: 'white', letterSpacing: '1px', fontWeight: '400', borderBottom: '2px solid orange' }}>
            LA TEAM SURVEILLANCE
        </Text>
      </Heading>
      {panelName != '' && showPanel && <Panel name={panelName} />}
      <Footer showPanel={setPanel}/>
      <video style={{ marginTop: '0', minWidth: '1900px' }} width="100%" controls={false} autoPlay muted loop>
        <source src="https://blz-contentstack-assets.akamaized.net/v3/assets/blt9c12f249ac15c7ec/blta5464cb78c39385a/63369a3de3c2a2741688cb6b/F2P_Trailer.mp4" type="video/mp4" />
      </video>
    </div>
  );
}
export default App;